import {defaultLocale, allowedLocales, domainLocalesMap, initialFetchState, defaultNavigation, defaultInquiryModalOpen} from '../../constants/defaultValues'

import {
    CHANGE_LOCALE,
    TOGGLE_DEV_STRINGS,
    INIT_STRINGS,
    TOGGLE_NAVIGATION,
    TOGGLE_INQUIRY_MODAL
} from '../types'
import isEmpty from "lodash/isEmpty"

const domain = window.location.host.split('.').reverse()[0]
let INIT_STATE_LOCALE = domainLocalesMap[domain] || defaultLocale

if(!allowedLocales.includes(INIT_STATE_LOCALE)){
    INIT_STATE_LOCALE = defaultLocale
}

export const locale = (state = INIT_STATE_LOCALE, action) => {
    switch (action.type) {
        case CHANGE_LOCALE:
            return action.payload
        default:
            return state
    }
}

export const navigation = (state = defaultNavigation, action) => {
    switch (action.type) {
        case TOGGLE_NAVIGATION:
            return !state
        default:
            return state
    }
}

export const inquiryModalOpen = (state = defaultInquiryModalOpen, action) => {
    switch (action.type) {
        case TOGGLE_INQUIRY_MODAL:
            return !state
        default:
            return state
    }
}

export const strings = (state = initialFetchState, action) => {
    switch (action.type) {
        case INIT_STRINGS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: action.message,
            }
        case TOGGLE_DEV_STRINGS:
            const newState = {}
            if (isEmpty(state.original)) {
                newState.original = state.data
                newState.data = {}
                Object.keys(state.data).map(key => {
                    newState.data[key] = key
                })
            } else {
                newState.original = {}
                newState.data = state.original
            }
            return {
                ...state,
                ...newState
            }
        default:
            return state
    }
}