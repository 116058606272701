export const defaultLocale = "cs-CZ"

export const allowedLocales = ["cs-CZ", 'sk-SK']

export const domainLocalesMap = {
    cz: 'cs-CZ',
    sk: 'sk-SK'
}

export const defaultNavigation = false

export const defaultInquiryModalOpen = false

//fetch state
export const initialFetchState = {
    data: {},
    isFetching: true,
    shouldReload: false,
    errors: {},
    message: ''
}


